// src/App.js
import React, { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Login from "./components/Login/Login";
import ChatbotPage from "./components/ChatbotPage/ChatbotPage";
import ForgotPassword from "./components/ForgotPassword/ForgotPassword";
import ResetPassword from "./components/ResetPassword/ResetPassword";
import ProtectedRoute from "./utils/ProtectedRoute";
import Checkout from "./components/Checkout/Checkout";
import AuthProvider from "./context/AuthContext";
import OrderSuccess from "./components/Order/OrderSuccess";
import Story from "./components/Pages/Story";
import SingleStory from "./components/SingleStory/SingleStory";
import NotFound from "./components/NotFound/NotFound";
import Chat from "./components/Pages/Chat";
import DashboardComponent from "./components/Dashboard/Dashboard";
import Prompt from "./components/Pages/Prompt";
import { ToastContainer } from "react-toastify";
import Coupon from "./components/Pages/Coupon";

const App = () => {
  return (
    <AuthProvider>
      <ToastContainer />
      <Routes>
        <Route path="*" element={<NotFound />} />
        <Route
          path="/"
          element={
            <ProtectedRoute>
              <DashboardComponent />
            </ProtectedRoute>
          }
        />
        <Route path="/prompts" element={<ProtectedRoute><Prompt /></ProtectedRoute>} />
        <Route path="/coupons" element={<ProtectedRoute><Coupon /></ProtectedRoute>} />

        <Route path="/start-your-story" element={<ProtectedRoute><ChatbotPage /></ProtectedRoute>} />

        <Route path="/login" element={<Login />} />

        <Route
          path="/checkout"
          element={
            <ProtectedRoute>
              <Checkout />
            </ProtectedRoute>
          }
        />
        <Route
          path="/order-success"
          element={
            <ProtectedRoute>
              <OrderSuccess />
            </ProtectedRoute>
          }
        />
        <Route
          path="/story"
          element={
            <ProtectedRoute>
              <Story />
            </ProtectedRoute>
          }
        />
        <Route
          path="/story/:id"
          element={
            <ProtectedRoute>
              <SingleStory />
            </ProtectedRoute>
          }
        />
          <Route
          path="/chat/:id"
          element={
            <ProtectedRoute>
              <Chat/>
            </ProtectedRoute>
          }
        />
      </Routes>
    </AuthProvider>
  );
};

export default App;
